<template>
  <b-card header-tag="header" class="shadow m-sm-5 p-sm-4">
    <template #header>
      <h5 class="mb-0">{{ $route.name }}</h5>
    </template>
    <ValidationObserver ref="form">
      <form @submit.prevent="onSubmit" v-if="showForm">
        <template v-for="(m, index) in model">
          <form-group-input
            :ref="index"
            :type="m.type"
            :label="typeof m.label === 'string' ? m.label : null"
            :options="getOptions(index)"
            :need-label="true"
            v-model="m.value"
            :rules="typeof m.rules !== 'undefined' ? m.rules : null"
            :disabled="typeof m.disabled !== 'undefined' ? m.disabled : null"
            :id="index"
            @valueDateRange="(e) => dateRange = e"
          />
        </template>
        <div>
          <b-button
            :href="'#/master-data/product-type'"
            variant="warning text-white"
            class="mt-3"
            ><font-awesome-icon
              icon="spinner"
              spin
              v-if="isLoading"
            ></font-awesome-icon>
            Batal</b-button
          >
          <b-button
            type="submit"
            variant="primary"
            class="mt-3 text-white float-right"
            ><font-awesome-icon
              icon="spinner"
              spin
              v-if="isLoading"
            ></font-awesome-icon>
            Simpan</b-button
          >
        </div>
      </form>
    </ValidationObserver>
  </b-card>
</template>

<script>
import {
  saveProcess,
  setModelValue,
  scrollToError,
  objectToOptions,
} from "@/_helpers";
import formGroupInput from "@/components/FormGroupInput.vue";
import ProductType from "@/models/ProductType.js";

const productTypeModel = new ProductType();

export default {
  components: {
    formGroupInput,
  },
  watch: {
    "$route.params.id": function(val) {
      if (this.mode === "update") {
        this.getDetail();
      }
    },
  },
  computed: {
    mode: function() {
      return this.$route.params.id !== undefined ? "update" : "create";
    },
  },
  data() {
    return {
      showForm: true,
      isLoading: false,
      dateRange: '',
      model: productTypeModel.init(),
    };
  },
  created: function() {
    this.getProductType();
  },
  mounted() {
    if (this.mode === "update") {
      this.getDetail();
    }
  },
  methods: {
    getProductType: async function() {
      const data = await productTypeModel.list({ parent: 1 });
      this.showForm = false;
      for (let data of data.data) {
        Object.assign(this.model.parent_id.options, { [data.id]: data.name });
      }
      await this.$nextTick();
      this.showForm = true;
    },
    getOptions(key) {
      return typeof this.model[key].options !== "undefined"
        ? objectToOptions(this.model[key].options)
        : null;
    },
    getDetail() {
      productTypeModel
        .find(this.$route.params.id)
        .then((resp) => {
          setModelValue(this.model, resp);
          Object.keys(this.model).forEach((key) => {
            this.$refs[key][0].setValue(this.model[key].value);
          });
        })
        .catch((error) => {
          if (process.env.NODE_ENV !== "production") {
            console.error(error);
          }
        });
    },
    resetForm() {
      this.$nextTick(() => {
        // clearModelValue(this.model)
        Object.keys(this.model).forEach((key) => {
          this.$refs[key][0].setValue(null);
        });
        this.$refs.form.reset();
      });
    },
    onSubmit() {
      this.$refs.form.validate().then((success) => {
        if (!success || this.isLoading) {
          scrollToError(this.$refs.form);
          return false;
        }
        saveProcess(this.model, this.mode === "update")
          .then((model) => {
            Object.assign(model, {
              date_range: this.dateRange
            })
            console.log(model)
            this.isLoading = true;
            if (this.mode === "update") {
              productTypeModel
                .update(this.$route.params.id, model)
                .then(() => {
                  this.isLoading = false;
                  this.$store.dispatch(
                    "notification/success",
                    "Data ProductType berhasil diubah."
                  );
                  this.$router.push(
                    "/master-data/product-type/" + this.$route.params.id
                  );
                })
                .catch((error) => {
                  this.isLoading = false;
                  this.$store.dispatch("notification/error", error);
                });
            } else {
              productTypeModel
                .create(model)
                .then((resp) => {
                  this.isLoading = false;
                  // this.resetForm()
                  this.$store.dispatch(
                    "notification/success",
                    "Data ProductType berhasil disimpan."
                  );
                  this.$router.push("/master-data/product-type/" + resp.id);
                })
                .catch((error) => {
                  this.isLoading = false;
                  this.$store.dispatch("notification/error", error);
                });
            }
          })
          .catch(() => {});
      });
    },
  },
};
</script>
